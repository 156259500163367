import React, { createContext, useEffect } from 'react';
import { METADATA } from '../store/actions';
import { apiEndpoints } from '../constants';
import axiosInstance from '../services/axios';
import { useDispatch } from 'react-redux';
import useAuth from '../hooks/useAuth';

const ShareContext = createContext({});

export const ShareProvider = ({ children }) => {
  const dispatch = useDispatch();
  const { isLoggedIn } = useAuth();

  function getMetadata() {
    axiosInstance.post(apiEndpoints.get_metadata, { outputtype: 'RawJson', guest: true }).then((response) => {
      if (response.status === 200 && response.data.return === 200) {
        const {
          province_list: provinces,
          gender_list: genders,
          weekday_list: weekday,
          dimensions,
          activity_types: activityTypes,
        } = response.data;
        dispatch({
          type: METADATA,
          provinces,
          genders,
          weekday,
          dimensions,
          activityTypes,
        });
      }
    });
  }

  useEffect(() => {
    if (isLoggedIn) getMetadata();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  return <ShareContext.Provider value={{ getMetadata }}>{children}</ShareContext.Provider>;
};

export default ShareContext;
