import axiosServices from '../../axios';
import { apiEndpoints } from '../../../constants';
export const getUserGroupDetail = (group_code, setView) => {
  return axiosServices
    .post(apiEndpoints.get_user_group_detail, { id: group_code })
    .then((response) => {
      if (response.status === 200 && response.data.return === 200) {
        console.log('api');
        const { view, data } = response.data;
        setView({ ...view, action: 'detail' });
        return data;
      }
      return {};
    })
    .catch((error) => {
      console.log(error);
    });
};
export const getUserGroupList = () => {
  return axiosServices
    .post(apiEndpoints.get_user_group_list, {})
    .then((response) => {
      if (response.status === 200 && response.data.return === 200) {
        console.log('apilist');
        const { list, user_group_list } = response.data;

        return { list, user_group_list };
      }
      return {};
    })
    .catch((error) => {
      console.log(error);
    });
};
export const createUserGroupDetail = (usergroup) => {
  return axiosServices
    .post(apiEndpoints.create_user_group_detail, {
      ...usergroup,
    })
    .then((response) => {
      if (response.status === 200 && response.data.return === 200) {
        return true;
      }
      return false;
    })
    .catch((error) => {
      console.log(error);
    });
};
export const updateUserGroupDetail = (usergroup) => {
  return axiosServices
    .post(apiEndpoints.update_user_group_detail, {
      ...usergroup,
    })
    .then((response) => {
      if (response.status === 200 && response.data.return === 200) {
        return true;
      }
      return false;
    })
    .catch((error) => {
      console.log(error);
    });
};

export const getTreeViewMenuList = () => {
  return axiosServices
    .post(apiEndpoints.get_menu_item_tree_view, {})
    .then((response) => {
      if (response.status === 200 && response.data.return === 200) {
        const { list } = response.data;
        return list;
      }
      return [];
    })
    .catch((error) => {
      console.log(error);
    });
};
export const getMenuLookupList = (user_group) => {
  return axiosServices
    .post(apiEndpoints.get_menu_item_lookup_tree_view, { user_group: user_group })
    .then((response) => {
      if (response.status === 200 && response.data.return === 200) {
        const { list } = response.data;
        return list;
      }
      return [];
    })
    .catch((error) => {
      console.log(error);
    });
};
export const updateMenuLookupList = (user_group, type, menu_code, is_add) => {
  return axiosServices
    .post(apiEndpoints.update_menu_item_update_lookup, {
      user_group: user_group,
      menu_code: menu_code,
      type: type,
      is_add: is_add,
    })
    .then((response) => {
      if (response.status === 200 && response.data.return === 200) {
        return true;
      }
      return false;
    })
    .catch((error) => {
      console.log(error);
    });
};
export const getUserByDepart = (department_code) => {
  return axiosServices
    .post(apiEndpoints.get_all_account_by_department_and_role_template, {
      department_code: department_code,
      page: 1,
      no_item_per_page: 100,
      search_text: '',
      order_by: '',
      order_type: '',
    })
    .then((response) => {
      if (response.status === 200 && response.data.return === 200) {
        return response.data.list;
      }
      return [];
    })
    .catch((error) => {
      console.log(error);
    });
};
