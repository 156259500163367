import * as actionTypes from './actions';

export const initialState = {
  folder: false,
  document: false,
  detailDocument: false,
  accountDocument: false,
  departmentDocument: false,
  processDeptDocument: false,
  processUserDocument: false,
  appDocument: false,
  assessment: false,
  project: false,
  risk: false,
  issueReleased: false,
  issueUnReleased: false,
};

const floatingMenuReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FLOATING_MENU_CHANGE:
      return {
        ...state,
        folder: action.folder,
        document: action.document,
        detailDocument: action.detailDocument,
        accountDocument: action.accountDocument,
        profileDocument: action.profileDocument,
        departmentDocument: action.departmentDocument,
        processDeptDocument: action.processDeptDocument,
        processUserDocument: action.processUserDocument,
        appDocument: action.appDocument,
        assessment: action.assessment,
        project: action.project,
        risk: action.risk,
        issueUnReleased: action.issueUnReleased,
        issueReleased: action.issueReleased,
      };
    default:
      return state;
  }
};

export default floatingMenuReducer;
