import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import usePlatform from '../../hooks/usePlatform.js';
import { ArrowBack, ArrowRightOutlined } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { CLOSE_PLATFORM, SET_PLATFORM, SET_ROLE } from './../../store/actions';
import { filterColor, iconDefault } from '../../constants/initial.js';
import { updateProfileSetting } from '../../services/api/User/index.js';
import { useHistory } from 'react-router-dom';
import AppService from './../../services/api/Menu/app';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiDialog-container': {
      alignItems: 'center',
    },
    '& .MuiDialog-paper': {
      width: 560,
      background: '#FFFFFF',
      borderRadius: 8,
    },
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
  },
  icon: {
    filter: filterColor,
  },
}));

const PlatformDialog = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { isOpen, platform: selectedPlatform } = useSelector((state) => state.platform);
  const { platforms } = usePlatform();
  const history = useHistory();

  const [roles, setRoles] = React.useState([]);

  const handleClickPlatform = (platform) => {
    dispatch({ type: SET_PLATFORM, platform });
  };

  const handleCloseDialog = () => {
    dispatch({ type: CLOSE_PLATFORM });
  };

  const handleClickRole = async (role) => {
    const res = await updateProfileSetting({
      platform_id: selectedPlatform.id,
      role_id: role.id,
      language: '',
    });
    if (res) {
      dispatch({ type: SET_ROLE, role });
      handleCloseDialog();
      history.push('/dashboard/app');
    }
  };

  const isPlatform = !Boolean(selectedPlatform?.id);

  React.useEffect(() => {
    async function fetchRole() {
      const res = await AppService.getRoleByPlatform(selectedPlatform.id);
      setRoles(res);
    }

    if (selectedPlatform.id) fetchRole();
  }, [selectedPlatform]);

  return (
    <Dialog className={classes.root} maxWidth="xs" open={isOpen}>
      <DialogTitle className={classes.title}>
        {isPlatform ? (
          'Chọn chức năng'
        ) : (
          <>
            <IconButton onClick={() => handleClickPlatform({})} size="small">
              <ArrowBack />
            </IconButton>
            Chọn role
          </>
        )}
      </DialogTitle>
      <DialogContent>
        <List>
          {isPlatform
            ? platforms?.map((item, index) => (
                <ListItemButton onClick={() => handleClickPlatform(item)} key={index}>
                  <ListItemIcon>
                    <img className={classes.icon} width="24" height="24" src={item.icon || iconDefault} alt="" />
                  </ListItemIcon>
                  <ListItemText primary={item.platform_name} />
                  <ArrowRightOutlined />
                </ListItemButton>
              ))
            : roles?.map((item, index) => (
                <ListItemButton onClick={() => handleClickRole(item)} key={index}>
                  <ListItemIcon>
                    <img width="24" height="24" src={item.icon || iconDefault} alt="" />
                  </ListItemIcon>
                  <ListItemText primary={item.value} />
                  <ArrowRightOutlined />
                </ListItemButton>
              ))}
        </List>
      </DialogContent>
      <DialogActions style={{ justifyContent: 'flex-start' }}>
        <Button variant="outlined" onClick={handleCloseDialog}>
          Đóng
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PlatformDialog;
