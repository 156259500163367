import { createTheme } from '@mui/material/styles';
import value from '../assets/scss/_themes-vars.scss';
import { grey } from '@mui/material/colors';

export function theme(customization) {
  const { navType } = customization;

  let textPrimary;
  let textSecondary;
  let textDark;
  let textHint;
  let background;
  let paper;
  let menuCaption;
  let textInversePrimary;

  switch (navType) {
    case 'dark':
      textPrimary = menuCaption = textInversePrimary = value.textDarkPrimary;
      textSecondary = value.textDarkSecondary;
      textDark = value.textDarkDark;
      textHint = value.textHintDark;

      background = value.backgoundDark;
      paper = value.paperDark;
      break;
    case 'light':
    default:
      textPrimary = textInversePrimary = menuCaption = value.textPrimary;
      textSecondary = value.textSecondary;
      textDark = value.textDark;
      textHint = value.textHint;

      background = value.backgound;
      paper = value.paper;
      break;
  }

  const theme = createTheme({
    direction: customization.rtlLayout ? 'rtl' : 'ltr',
    palette: {
      mode: navType === 'dark' ? 'dark' : 'light',
      primary: {
        light: value.primaryLight,
        main: value.primary,
        dark: value.primaryDark,
        100: value.primary100,
      },
      secondary: {
        light: value.secondaryLight,
        main: value.secondary,
        dark: value.secondaryDark,
      },
      error: {
        light: value.errorLight,
        main: value.error,
        dark: value.errorDark,
      },
      warning: {
        light: value.warningLight,
        main: value.warning,
        dark: value.warningDark,
      },
      info: {
        light: value.infoLight,
        main: value.info,
        dark: value.infoDark,
      },
      success: {
        light: value.successLight,
        main: value.success,
        dark: value.successDark,
      },
      grey: {
        300: value.grey300,
        400: value.grey400,
      },
      bg: {
        bg100: value.bg100,
        secondary: value.bgSecondary,
      },
      textDark: {
        color: textDark,
      },
      text: {
        primary: textPrimary,
        secondary: textSecondary,
        dark: textDark,
        hint: textHint,
        disabled: textPrimary,
      },
      background: {
        paper: paper,
        default: background,
      },
    },
    typography: {
      fontFamily: `'Inter', sans-serif`,
      h6: {
        fontWeight: 600,
        color: textSecondary,
        fontSize: '1rem',
      },
      h5: {
        fontSize: '1.125rem',
        color: textSecondary,
        fontWeight: 600,
      },
      h4: {
        fontSize: '1.25rem',
        color: textSecondary,
        fontWeight: 600,
      },
      h3: {
        fontSize: '1.5rem',
        color: textDark,
        fontWeight: 600,
      },
      h2: {
        fontSize: '2rem',
        color: textDark,
        fontWeight: 600,
      },
      h1: {
        fontSize: '2.2rem',
        color: textDark,
        fontWeight: 600,
      },
      subtitle1: {
        fontSize: '0.875rem',
        fontWeight: 500,
        color: textSecondary,
        lineHeight: '1.643em',
      },
      subtitle2: {
        fontSize: '0.8125rem',
        fontWeight: 400,
      },
      caption: {
        fontSize: '0.68rem',
        color: textHint,
        fontWeight: 500,
      },
      body1: {
        fontSize: '0.875rem',
        fontWeight: 400,
        lineHeight: '1.643em',
      },
      body2: {
        letterSpacing: '0em',
        fontWeight: 400,
        lineHeight: '1.643em',
      },
      menuCaption: {
        fontSize: '0.6875rem',
        fontWeight: 600,
        color: value.primary,
        padding: '5px 15px 5px',
        textTransform: 'uppercase',
        marginTop: '10px',
      },
      subMenuCaption: {
        fontSize: '0.6875rem',
        fontWeight: 400,
        color: menuCaption,
        textTransform: 'capitalize',
      },
      subHeading: {
        color: 'red',
      },
      cardTitle: {
        color: value.primary,
        fontSize: '1rem',
      },
      breadcrumbTitle: {
        fontWeight: 500,
        fontSize: '1.5rem',
        color: textDark,
      },
    },
    components: {
      MuiAccordion: {
        styleOverrides: {
          root: {
            boxShadow: 'none',
          },
        },
      },
      MuiAccordionSummary: {
        styleOverrides: {
          root: {
            fontWeight: 600,
            fontSize: '0.875rem',
          },
          content: {
            color: textSecondary,
            fontWeight: 500,
          },
        },
      },
      MuiAppBar: {
        styleOverrides: {
          colorPrimary: {
            backgroundColor: value.primary,
            color: value.primaryWhite,
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          elevation1: {
            boxShadow: '0 4px 6px -2px rgb(0 0 0 / 12%), 0 2px 2px -1px rgb(0 0 0 / 5%)',
          },
          rounded: {
            borderRadius: '10px',
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: 'none',
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            // border:'1px solid rgba(33, 40, 50, 0.125)'
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          scrollPaper: {
            alignItems: 'normal',
          },
        },
      },
      MuiDialogActions: {
        styleOverrides: {
          root: {
            padding: '8px 24px',
          },
        },
      },
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            background: '#cecece',
          },
        },
      },
      MuiCardHeader: {
        styleOverrides: {
          root: {
            color: textDark,
            padding: '24px',
            //backgroundColor: headerBackColor,
          },
        },
      },
      MuiCardContent: {
        styleOverrides: {
          root: {
            padding: '24px',
          },
        },
      },
      MuiCardActions: {
        styleOverrides: {
          root: {
            padding: '24px',
          },
        },
      },
      MuiSvgIcon: {
        styleOverrides: {
          root: {
            fontSize: '1.3rem',
          },
        },
      },
      MuiTable: {
        styleOverrides: {
          root: {
            border: '1px solid #E8EAEC',
            borderRadius: '4px',
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            padding: '8px 15px',
            whiteSpace: 'nowrap',
          },
          head: {
            padding: '12px 15px',
            color: textDark,
            fontWeight: 600,
          },
          paddingCheckbox: {
            paddingLeft: '12px',
            position: 'relative',
          },
        },
      },
      MuiList: {
        styleOverrides: {
          root: {
            overflow: 'hidden',
          },
        },
      },
      MuiListItem: {
        styleOverrides: {
          root: {
            color: textInversePrimary,
            paddingTop: '12px',
            paddingBottom: '12px',
            '&$selected': {
              color: customization.navType === 'dark' ? value.menuHover : value.primary,
              backgroundColor: customization.navType !== 'dark' ? value.menuHover : value.primary,
              '&:hover': {
                backgroundColor: customization.navType !== 'dark' ? value.menuHover : value.primary,
              },
              '& .MuiListItemIcon-root': {
                color: customization.navType === 'dark' ? value.menuHover : value.primary,
              },
            },
            '&:hover': {
              color: customization.navType === 'dark' ? value.menuHover : value.primary,
              '& .MuiListItemIcon-root': {
                color: customization.navType === 'dark' ? value.menuHover : value.primary,
              },
            },
          },
          button: {
            '&:hover': {
              backgroundColor: customization.navType !== 'dark' ? value.menuHover : value.primary,
            },
          },
        },
      },
      MuiListItemButton: {
        styleOverrides: {
          root: {
            color: textInversePrimary,
            '&.Mui-selected': {
              color: customization.navType === 'dark' ? value.menuHover : value.primary,
              backgroundColor: customization.navType !== 'dark' ? value.menuHover : value.primary,
              '&:hover': {
                backgroundColor: customization.navType !== 'dark' ? value.menuHover : value.primary,
              },
              '& .MuiListItemIcon-root': {
                color: customization.navType === 'dark' ? value.menuHover : value.primary,
              },
            },
            '&:hover': {
              color: customization.navType === 'dark' ? value.menuHover : value.primary,
              backgroundColor: customization.navType !== 'dark' ? value.menuHover : value.primary,
              '& .MuiListItemIcon-root': {
                color: customization.navType === 'dark' ? value.menuHover : value.primary,
              },
            },
          },
        },
      },
      MuiListItemIcon: {
        styleOverrides: {
          root: {
            minWidth: '36px',
            color: textInversePrimary,
          },
        },
      },
      MUIDataTableSelectCell: {
        styleOverrides: {
          fixedLeft: {
            position: 'relative',
          },
        },
      },
      MuiTableHead: {
        styleOverrides: {
          root: {
            background: '#F4F5F6',
            //background: '#fbfdfe'
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          colorSecondary: {
            color: grey[100],
          },
          colorPrimary: {
            color: grey[100],
          },
          root: {
            color: grey[100],
            borderRadius: 4,
          },
          outlined: {
            color: grey[500],
          },
        },
      },
      MuiTimelineDot: {
        styleOverrides: {
          defaultGrey: {
            background: grey[300],
          },
        },
      },
      MuiTimelineConnector: {
        styleOverrides: {
          root: {
            background: grey[300],
          },
        },
      },
      MuiTableContainer: {
        styleOverrides: {
          root: {
            boxShadow: 'none',
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            minWidth: '0',
            textTransform: 'none',
          },
        },
      },
      MuiTabPanel: {
        styleOverrides: {
          root: {
            padding: '16px 0',
          },
        },
      },
      MuiAvatar: {
        styleOverrides: {
          colorDefault: {
            backgroundColor: value.textHint,
            color: grey[100],
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            color: textDark,
            fontSize: '0.875rem',
          },
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            fontSize: '0.875rem',
            color: textSecondary,
          },
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            padding: '8px',
          },
        },
      },
      MuiSnackbar: {
        styleOverrides: {
          root: {
            top: customization.headerPosition === 'fixed' ? '60px' : '0px',
          },
          anchorOriginTopCenter: {
            top: customization.headerPosition === 'fixed' ? '120px' : '60px',
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          icon: {
            color: textSecondary,
          },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          tag: {
            color: value.primary,
            backgroundColor: 'transparent',
            border: '1px solid ' + value.primary,
            '& .MuiChip-deleteIcon': {
              color: value.primary,
            },
          },
        },
      },
    },
  });

  return theme;
}

export default theme;
