import React, { useEffect, useState } from 'react';
import {
  Grid,
  Button,
  Slide,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Typography,
  Snackbar,
  Checkbox,
  TextField,
} from '@mui/material';
import { TreeView } from '@mui/lab';
import TreeItem from '@mui/lab/TreeItem';
import TreeItemClassKey from '@mui/lab/TreeItem/TreeItem';
import SvgIcon from '@mui/material/SvgIcon';
import Alert from '../../../../component/Alert/index.js';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useDialog50Styles } from '../../style.js';
import { FLOATING_MENU_CHANGE } from '../../../../store/actions.js';
import { getTreeViewMenuList, getMenuLookupList, updateMenuLookupList } from '../../../../services/api/UserGroup/index';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={0}>{children}</Box>}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const UserGroupMenuItemModal = () => {
  const classes = useDialog50Styles({ width: 50 });
  const dispatch = useDispatch();
  const [tabIndex, setTabIndex] = React.useState(0);

  const [listChecked, setListChecked] = useState([]);
  const { detailDocument: openDialog } = useSelector((state) => state.floatingMenu);
  const { selectedDocument } = useSelector((state) => state.document);
  const handleCloseDialog = () => {
    setDocumentToDefault();
    dispatch({ type: FLOATING_MENU_CHANGE, accountDocument: false });
  };
  const [snackbarStatus, setSnackbarStatus] = useState({
    isOpen: false,
    type: '',
    text: '',
  });
  const handleOpenSnackbar = (isOpen, type, text) => {
    setSnackbarStatus({
      isOpen: isOpen,
      type: type,
      text: text,
    });
  };
  const handleChecked = async (value, data) => {
    let check = listChecked.some((item) => item === value);
    if (check) {
      let filterarray = listChecked.filter((item) => item !== value);
      setListChecked([...filterarray]);
    } else {
      setListChecked([...listChecked, value]);
    }
    try {
      await updateMenuLookupList(selectedDocument.group_name, data.type, data.id, !check);
      handleOpenSnackbar(true, 'success', 'Cập nhật thành công!');
    } catch (error) {
      handleOpenSnackbar(true, 'error', 'Cập nhật thất bại!');
    } finally {
    }
  };

  const setDocumentToDefault = async () => {
    setTabIndex(0);
  };
  const [dataShow, setData] = React.useState();
  useEffect(() => {
    const fetch = async () => {
      let data = await getTreeViewMenuList();
      setData(data);
    };
    fetch();
  }, []);
  useEffect(() => {
    if (!selectedDocument) return;
    const fetch = async () => {
      let list = await getMenuLookupList(selectedDocument?.group_name);
      setListChecked([...list]);
    };
    fetch();
  }, [selectedDocument]);

  const renderItem = (data) => {
    if (data.children.length === 0) {
      return (
        <TreeItem
          nodeId={data.id}
          label={
            data.is_disable ? (
              <>
                <Checkbox inputProps={{ 'aria-label': 'primary checkbox' }} disabled />
                {data.name}
              </>
            ) : (
              <>
                <Checkbox
                  checked={listChecked?.some((item) => item === data.id)}
                  onChange={(e) => handleChecked(data.id, data)}
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
                {data.name}
              </>
            )
          }
          key={data.id}
          className={TreeItemClassKey.MuiTreeItemlabel}
        />
      );
    } else {
      return (
        <TreeItem
          nodeId={data.id}
          label={
            data.is_disable ? (
              <>
                <Checkbox inputProps={{ 'aria-label': 'primary checkbox' }} disabled />
                {data.name}
              </>
            ) : (
              <>
                <Checkbox
                  checked={listChecked?.some((item) => item === data.id)}
                  onChange={(e) => handleChecked(data.id, data)}
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
                {data.name}
              </>
            )
          }
          key={data.id}
        >
          {data.children.map((data2) => renderItem(data2))}
        </TreeItem>
      );
    }
  };

  function MinusSquare(props) {
    return (
      <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
        <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
      </SvgIcon>
    );
  }

  function PlusSquare(props) {
    return (
      <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
        <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
      </SvgIcon>
    );
  }

  return (
    <React.Fragment>
      {snackbarStatus.isOpen && (
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          open={snackbarStatus.isOpen}
          autoHideDuration={3000}
          onClose={() => setSnackbarStatus({ ...snackbarStatus, isOpen: false })}
        >
          <Alert
            onClose={() => setSnackbarStatus({ ...snackbarStatus, isOpen: false })}
            severity={snackbarStatus.type}
            sx={{ width: '100%' }}
          >
            {snackbarStatus.text}
          </Alert>
        </Snackbar>
      )}

      <Grid container>
        <Dialog
          open={openDialog || false}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleCloseDialog}
          className={classes.dialog}
        >
          <DialogTitle className={classes.dialogTitle}>
            <Typography variant="h4">Thông tin phân quyền</Typography>
            <IconButton className={classes.closeButton} onClick={handleCloseDialog} size="large">
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent className={classes.dialogContent}>
            <TabPanel value={tabIndex} index={0}>
              <Grid container spacing={1}>
                <Grid item lg={12} md={12} xs={12}>
                  <Typography variant="body1" gutterBottom>
                    Nhóm người dùng:
                  </Typography>
                  <TextField
                    fullWidth
                    size="small"
                    variant="outlined"
                    name="group_name"
                    value={selectedDocument?.group_name}
                    disabled
                  />
                </Grid>
                <Grid item lg={12} md={12} xs={12}>
                  <Grid container className={classes.gridItemInfo} alignItems="center">
                    {dataShow && (
                      <TreeView
                        style={{
                          padding: 5,
                          minHeight: 680,
                          maxHeight: 680,
                          background: '#fff',
                          overflow: 'auto',
                          maxWidth: 900,
                          minWidth: 900,
                        }}
                        aria-label="file system navigator"
                        defaultCollapseIcon={<MinusSquare />}
                        defaultExpandIcon={<PlusSquare />}
                        sx={{ height: 264, flexGrow: 1, maxWidth: 500, minWidth: 500, overflowY: 'auto' }}
                      >
                        <>{dataShow.map((data) => renderItem(data))}</>
                      </TreeView>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </TabPanel>
          </DialogContent>
          <DialogActions>
            <Grid container justifyContent="space-between">
              <Grid item>
                <Button variant="outlined" onClick={() => handleCloseDialog()}>
                  Đóng
                </Button>
              </Grid>

              <Grid item></Grid>
            </Grid>
          </DialogActions>
        </Dialog>
      </Grid>
    </React.Fragment>
  );
};
export default UserGroupMenuItemModal;
