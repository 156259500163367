import axiosServices from '../../axios';
import { apiEndpoints } from '../../../constants';

export const getAccountDetail = async (id, setView) => {
  return axiosServices
    .post(apiEndpoints.get_account_detail, {
      outputtype: 'RawJson',
      id,
    })
    .then((response) => {
      if (response.status === 200 && response.data.return === 200) {
        const { data, view, user_group_list, treeview } = response.data;
        setView({ ...view, action: 'detail' });
        return { data, user_group_list, treeview };
      } else return {};
    });
};
export const getAccount = async (id) => {
  return axiosServices
    .post(apiEndpoints.get_account_detail, {
      outputtype: 'RawJson',
      id,
    })
    .then((response) => {
      if (response.status === 200 && response.data.return === 200) {
        const { data, treeview } = response.data;
        return { data, treeview };
      } else return {};
    });
};

export const getAllTask = async () => {
  return axiosServices
    .post(apiEndpoints.get_all_task, {
      outputtype: 'RawJson',
      company_id: null,
    })
    .then((response) => {
      if (response.status === 200 && response.data.return === 200) {
        const { list: news } = response.data;
        // setView({ ...view, action: 'detail' });
        return news;
      } else return {};
    });
};
export const getAllUser = async () => {
  return axiosServices
    .post(apiEndpoints.get_all_account_list, {
      outputtype: 'RawJson',
    })
    .then((response) => {
      if (response.status === 200 && response.data.return === 200) {
        const { list } = response.data;
        return list;
      } else return [];
    });
};
export const getAllUserByDept = async (department_code, role_template_code) => {
  return axiosServices
    .post(apiEndpoints.get_all_account_by_department_and_role_template, {
      department_code: department_code,
      role_template_code: role_template_code,
      outputtype: 'RawJson',
      page: 1,
      no_item_per_page: 100,
      search_text: '',
    })
    .then((response) => {
      if (response.status === 200 && response.data.return === 200) {
        const { list, all_user } = response.data;
        return { list, all_user };
      } else return [];
    });
};
export const createAccount = async (account) => {
  return axiosServices.post(apiEndpoints.create_account, account).then((response) => {
    if (response.status === 200 && response.data.return === 200) return true;
    return false;
  });
};
export const activeAccount = async (account) => {
  return axiosServices
    .post(apiEndpoints.active_account, {
      outputtype: 'RawJson',
      ...account,
    })
    .then((response) => {
      if (response.status === 200 && response.data.return === 200) return true;
      return false;
    });
};
export const updateAccount = async (account) => {
  return axiosServices
    .post(apiEndpoints.update_account, {
      ...account,
      outputtype: 'RawJson',
    })
    .then((response) => {
      if (response.status === 200 && response.data.return === 200) return true;
      return false;
    });
};
export const assignAccount = async (account) => {
  return axiosServices
    .post(apiEndpoints.assign_account_to_dept, {
      ...account,
      outputtype: 'RawJson',
    })
    .then((response) => {
      if (response.status === 200 && response.data.return === 200) return true;
      return false;
    });
};
export const removeAccount = async (account) => {
  return axiosServices
    .post(apiEndpoints.remove_account_from_dept, {
      ...account,
      outputtype: 'RawJson',
    })
    .then((response) => {
      if (response.status === 200 && response.data.return === 200) return true;
      return false;
    });
};
export const updatePermisstionGroup = async (id, permission_group, email_address, group_name_list) => {
  return axiosServices
    .post(apiEndpoints.update_user_group_account, {
      id: id,
      permission_group: permission_group,
      email_address: email_address,
      group_name_list: group_name_list,
    })
    .then((response) => {
      if (response.status === 200 && response.data.return === 200) return true;
      return false;
    });
};
export const getPermisstionGroup = async (account_id) => {
  return axiosServices
    .post(apiEndpoints.get_user_group_list_by_account, { account_id: account_id })
    .then((response) => {
      if (response.status === 200 && response.data.return === 200) {
        const { data, list } = response.data;
        return { data, list };
      }
      return {};
    });
};
export const resetPassword = async (new_password, password, email_address) => {
  return axiosServices
    .post(apiEndpoints.reset_password, {
      email_address: email_address,
      new_password: new_password,
      password: password,
    })
    .then((response) => {
      if (response.status === 200 && response.data.return === 200) {
        return true;
      }
      return false;
    });
};
export const getAccountByDepartment = async (department_code, page, no_item_per_page) => {
  return axiosServices
    .post(apiEndpoints.get_all_account_by_department_and_role_template, {
      page: page,
      no_item_per_page: no_item_per_page,
      search_text: '',
      department_code: department_code,
      order_by: '',
      order_type: '',
    })
    .then((response) => {
      if (response.status === 200 && response.data.return === 200) {
        const { list } = response.data;
        return list;
      }
      return [];
    });
};
export const removeAccountFromGroup = async (account) => {
  return axiosServices
    .post(apiEndpoints.remove_account_to_group, {
      ...account,
      outputtype: 'RawJson',
    })
    .then((response) => {
      if (response.status === 200 && response.data.return === 200) return true;
      return false;
    });
};
export const assignAccountToGroup = async (account) => {
  return axiosServices
    .post(apiEndpoints.add_account_to_group, {
      ...account,
      outputtype: 'RawJson',
    })
    .then((response) => {
      if (response.status === 200 && response.data.return === 200) return true;
      return false;
    });
};

export const updateProfileSetting = async (data) => {
  return axiosServices
    .post(apiEndpoints.update_profile_setting, {
      ...data,
      outputtype: 'RawJson',
    })
    .then((response) => {
      if (response.status === 200 && response.data.return === 200) return true;
      return false;
    });
};

export const getProfileSetting = async () => {
  return axiosServices.post(apiEndpoints.get_profile_setting, {}).then((response) => {
    if (response.status === 200 && response.data.return === 200) return response.data;
    return false;
  });
};
