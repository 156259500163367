import React from 'react';

import NavGroup from './NavGroup';
import { useSelector } from 'react-redux';
// import useFolder from '../../../../hooks/useFolder';

const MenuList = (props) => {
  const { drawerToggle, drawerOpen } = props;
  // const { getFolders } = useFolder();
  // const { projects } = useSelector((state) => state.project);
  // const selectedProject = projects?.find((project) => project.selected);
  const { apps } = useSelector((state) => state.app);
  // const { selectedApp } = useSelector((state) => state.app);

  // useEffect(() => {
  //   if (selectedProject) {
  //     getFolders(selectedProject, selectedApp?.id);
  //   }
  // }, [getFolders, selectedApp, selectedProject]);

  const navItems = apps?.map((item, index) => {
    return <NavGroup key={index} item={item} drawerToggle={drawerToggle} drawerOpen={drawerOpen} />;
  });

  return navItems;
};

export default MenuList;
