import { makeStyles } from '@mui/styles';

export const useDialog50Styles = makeStyles((theme) => ({
  dialog: {
    '&>div:nth-child(3)': {
      justifyContent: 'flex-end',
      '&>div': {
        margin: '0px',
        borderRadius: '0px',
        width: (props) => `${props.width}%` || '50%',
        maxWidth: '100%',
        maxHeight: '100%',
      },
    },
  },
  dialogTitle: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: '0px 1px 0px #E8EAEC',
    position: 'relative',
    '& h4': {
      marginRight: 20,
    },
  },
  dialogAction: {
    justifyContent: 'space-between',
  },
  itemFile: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    gap: '12px',
    cursor: 'pointer',
    position: 'relative',
  },
  fileName: {
    textAlign: 'center',
    color: '#000',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    '& > img': {
      marginRight: '4px',
    },
  },
  iconUpload: {
    width: '200px',
    height: '200px',
    border: '10px',
    boxShadow: 'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px',
  },
  history: {
    position: 'relative',
    '&>*': {
      position: 'relative',
      zIndex: '5',
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      top: '0',
      left: '23px',
      width: '2px',
      height: '100%',
      background: '#ebebeb',
      zIndex: '1',
    },
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: '50%',
    transform: 'translateY(-50%)',
    color: theme.palette.grey[500],
  },
  timeline: {
    padding: 0,
    margin: 0,
    '& .MuiTimelineContent-root': {
      flex: 3,
    },
  },
  iconUpload1: {
    width: '50px',
    height: '50px',
  },
  delete: {
    position: 'absolute',
    top: '0',
    right: '0',
  },
}));

export const useDialog100Styles = makeStyles((theme) => ({
  dialog: {
    '&>div:nth-child(3)': {
      justifyContent: 'flex-end',
      '&>div': {
        margin: '0px',
        borderRadius: '0px',
        width: (props) => `${props.width}%` || '100%',
        maxWidth: '100%',
        minWidth: '100%',
        maxHeight: '100%',
      },
    },
  },
  tabItem: {
    background: '#FFFFFF',
    borderRadius: '8px',
    marginBottom: '8px',
    boxShadow: 'rgb(50 50 93 / 25%) 0px 2px 5px -1px, rgb(0 0 0 / 30%) 0px 1px 3px -1px',
  },
  tabItemNoteSelection: {
    display: 'flex',
    alignItems: 'flex-end',
    padding: '16px',
    borderBottom: '1px solid #ddd',
  },
  tabItemNoteSelectionLabel: {
    width: '120px',
    fontWeight: 'bold',
    lineHeight: 1,
  },
  selectedNoteListSection: {
    padding: '16px',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    widht: '100%',
    '&::-webkit-scrollbar': {
      height: '8px',
    },
    '&::-webkit-scrollbar-track': {
      background: '#f1f1f1',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#888',
    },
    [theme.breakpoints.down('sm')]: {
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
  },
  selectedNoteItem: {
    display: 'flex',
    alignItems: 'center',
    padding: '4px 8px',
    borderRadius: '30px',
    background: '#642BF6',
    color: '#FFF',
    margin: '0 4px 4px 0',
  },
  tabItemNoteInputWrap: {
    flexDirection: 'column',
    alignItems: 'baseline',
    paddingTop: '8px',
    paddingBottom: '16px',
  },
  tabItemNoteInput: {
    marginTop: '8px',
  },
  tabItemAssessSection: {},
  tabItemAssessTitle: {
    overflow: 'hidden',
    height: '78px',
    padding: '8px 16px',
    background: '#EBAB01',
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px',
    textAlign: 'center',
    position: 'relative',
    fontSize: '24px',
    lineHeight: '58px',
    fontWeight: 'bold',
    color: '#FFF',
  },
  tabItemAssessCup: {
    width: '60px',
    position: 'absolute',
    bottom: 0,
    left: '2px',
    transform: 'rotate(30deg) translateY(20px)',
  },
  tabItemTitle: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '16px',
    marginBottom: '16px',
    borderBottom: '1px solid #ddd',
    width: '100%',
  },
  tabItemLabel: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& span': {
      marginLeft: '8px',
    },
  },
  tabItemEdit: {
    display: 'flex',
    marginLeft: 'auto',
    alignItems: 'center',

    cursor: 'pointer',
    '& span': {
      marginLeft: '8px',
    },
  },
  tabItemBody: {
    padding: '0 16px 16px',
    overflow: 'hidden',
  },
  tableAssessItemLabel: {
    display: 'flex',
    flexDirection: 'column',
  },
  dialogTitle: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: '0px 1px 0px #E8EAEC',
    position: 'relative',
    '& h4': {
      marginRight: 20,
    },
  },
  dialogAction: {
    justifyContent: 'space-between',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: '50%',
    transform: 'translateY(-50%)',
    color: theme.palette.grey[500],
  },
  avatar: {
    width: 72,
    height: 72,
  },
  flexColumn: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
    flexDirection: 'column',
  },
  list: {
    border: '1px solid #A2ACB4',
    borderRadius: 4,
    padding: 0,
  },
  name: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  success: {
    background: theme.palette.success.main,
    color: theme.palette.success.contrastText,

    '&:hover': {
      background: theme.palette.success.dark,
    },
  },
  chip: {
    background: theme.palette.bg.secondary,
  },
  wrapText: {
    whiteSpace: 'normal',
    wordWrap: 'break-word',
  },
  tabAlign: {
    alignItems: 'center',
  },
  itemFile: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    gap: '12px',
    cursor: 'pointer',
    position: 'relative',
  },
  fileName: {
    textAlign: 'center',
    color: theme.palette.text.primary,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 1,
    '-webkit-box-orient': 'vertical',
    '& > img': {
      marginRight: '4px',
    },
  },
  iconUpload: {
    width: '50px',
    height: '50px',
  },
  timelinePaper: {
    padding: '6px 16px',
  },
  timeline: {
    padding: 0,
    margin: 0,
    '& .MuiTimelineOppositeContent-root': {
      flex: 'inherit',
    },
  },
  timelineIcon: {
    width: '18px',
    height: '18px',
    objectFit: 'cover',
  },
}));

export const useDialogCenterStyles = makeStyles((theme) => ({
  root: {
    '& .MuiDialog-container': {
      alignItems: 'center',
    },
    '& .MuiDialog-paper': {
      width: (props) => props.width || 500,
      background: '#FFFFFF',
      borderRadius: 4,
    },
  },
  title: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: '0px 1px 0px #E8EAEC',
    position: 'relative',
  },
  content: {
    padding: theme.spacing(3),

    '& > p': {
      marginBottom: 6,
    },
  },
  action: {
    padding: theme.spacing(2, 3),
    display: 'flex',
    justifyContent: 'space-between',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: '50%',
    transform: 'translateY(-50%)',
    color: theme.palette.grey[500],
  },
}));
