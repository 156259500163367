import { Divider, Grid, Typography, LinearProgress, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import React from 'react';
import Dropzone, { useDropzone } from 'react-dropzone';
import { gridSpacing } from '../../constants';
import { useDispatch, useSelector } from 'react-redux';
import { UPLOAD_CLOSE } from './../../store/actions';
import { openSnackBarTop } from '../../utils/helper.js';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { storage } from '../../services/firebase.js';

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: `translate(-50%, -50%)`,
    width: 500,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 2),
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  dropzone: {
    textAlign: 'center',
    padding: theme.spacing(4, 3),
    border: '3px dashed #eeeeee',
    backgroundColor: '#fafafa',
    color: '#6e6e6e',
    cursor: 'pointer',
    marginBottom: '20px',
    fontSize: '15px',
  },
  progress: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  fileName: {
    wordWrap: 'break-word',
  },
}));

export default function Upload() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [selectedFiles, setSelectedFile] = React.useState([]);
  const [isUploading, setIsUploading] = React.useState(false);
  const { open: openDialog, onSuccess, folder, fileType: type } = useSelector((state) => state.upload);
  const [fileType, setFileType] = React.useState(type);
  const [progresspercent, setProgresspercent] = React.useState(0);
  function onDrop(files) {
    if (type?.toLowerCase() !== 'video')
      if (files[0].size / 1024 / 1024 > 5) {
        openSnackBarTop('error', 'Dung lượng file không được quá 5MB', dispatch);
        return;
      } else if (files[0].size / 1024 / 1024 > 25) {
        openSnackBarTop('error', 'Dung lượng file không được quá 25MB', dispatch);
        return;
      }
    setSelectedFile(files);
  }

  const { getRootProps, getInputProps } = useDropzone({
    accept: fileType,
    onDrop,
  });

  const onClose = () => {
    dispatch({ type: UPLOAD_CLOSE });
    setSelectedFile([]);
    setIsUploading(false);
    setProgresspercent(0);
  };

  async function uploadToStorage(event) {
    const fileData = {};
    setIsUploading(true);
    const storageRef = ref(storage, `Upload/${folder}/${new Date().getTime()}_${selectedFiles[0].name}`);
    const uploadTask = uploadBytesResumable(storageRef, selectedFiles[0]);
    fileData.file_name = selectedFiles[0].name;

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
        setProgresspercent(progress);
      },
      (error) => {
        openSnackBarTop('Tải lên thất bại', 'error');
        setIsUploading(false);
        setProgresspercent(0);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          fileData.url = downloadURL;
          setSelectedFile([]);
          setProgresspercent(0);
          onSuccess(fileData);
          setIsUploading(false);
          onClose();
        });
      }
    );
  }

  React.useEffect(() => {
    switch (type?.toLowerCase()) {
      case 'image':
        setFileType('image/*');
        break;
      case 'audio':
        setFileType('audio/*');
        break;
      case 'pdf':
        setFileType('.pdf');
        break;
      case 'excel':
        setFileType('.xls,.xlsx');
        break;
      case 'zip':
        setFileType('.zip,.rar');
        break;
      case 'other':
        setFileType('.csv,.doc,.docx,.xls,.xlsx,.txt,.pdf,image/*');
        break;
      case 'video':
        setFileType('.mp4');
        break;
      default:
        setFileType('image/*');
        break;
    }
  }, [type]);

  return (
    <Modal open={openDialog || false} onClose={onClose}>
      <div className={classes.paper}>
        <Typography variant="h6">Tải file lên</Typography>
        <Divider className={classes.divider} />
        {!isUploading && (
          <Dropzone multiple={false}>
            {() => (
              <section>
                <div {...getRootProps({ className: classes.dropzone })}>
                  <input {...getInputProps()} />
                  {selectedFiles.length && selectedFiles[0].name ? (
                    <div className={classes.fileName}>{selectedFiles.length && selectedFiles[0].name}</div>
                  ) : (
                    'Kéo thả file hoặc bấm vào đây để bắt đầu chọn'
                  )}
                </div>
              </section>
            )}
          </Dropzone>
        )}
        {isUploading && (
          <Box>
            <Box>
              <Typography variant="h4">Đang tải lên</Typography>
            </Box>
            <Box display="flex" alignItems="center" className={classes.progress}>
              <Box width="100%" mr={1}>
                <LinearProgress variant="determinate" value={progresspercent} />
              </Box>
              <Box minWidth={35}>
                <Typography variant="body2" color="textSecondary">{`${progresspercent}%`}</Typography>
              </Box>
            </Box>
          </Box>
        )}
        <Grid container justifyContent="flex-end" spacing={gridSpacing}>
          <Grid item>
            <Button variant="outlined" onClick={onClose}>
              Đóng
            </Button>
          </Grid>
          <Grid item>
            <Button
              disabled={!selectedFiles[0]?.name || isUploading}
              variant="contained"
              color="primary"
              onClick={uploadToStorage}
            >
              Tải lên
            </Button>
          </Grid>
        </Grid>
      </div>
    </Modal>
  );
}
