import React from 'react';
import { Box, Hidden, IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ProfileSection from './ProfileSection';
import NotificationSection from './NotificationSection';
import logo from '../../../assets/images/logo.png';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { DOCUMENT_CHANGE, FOLDER_CHANGE, PROJECT_CHANGE, SELECTED_APP_CHANGE } from './../../../store/actions';
import MenuIcon from '@mui/icons-material/Menu';

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(1.25),
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  menuIcon: {
    fontSize: '1.5rem',
  },
  logoSize: {
    width: '100%',
    height: '40px',
    objectFit: 'contain',
  },
}));

const Header = ({ drawerOpen, setDrawerOpen }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleLogoClick = () => {
    dispatch({ type: SELECTED_APP_CHANGE, app: {} });
    dispatch({ type: PROJECT_CHANGE, projects: [] });
    dispatch({ type: FOLDER_CHANGE, folder: [] });
    dispatch({ type: DOCUMENT_CHANGE, selectedDocument: null });
  };

  return (
    <React.Fragment>
      <IconButton color="primary" onClick={() => setDrawerOpen(true)} edge="start">
        <MenuIcon />
      </IconButton>
      <Hidden mdDown>
        <Box component={Link} to="/" onClick={handleLogoClick} style={{ cursor: 'pointer', display: 'flex' }}>
          <img src={logo} alt="Logo" className={classes.logoSize} />
        </Box>
      </Hidden>
      <div className={classes.grow} />
      <NotificationSection />
      <ProfileSection />
    </React.Fragment>
  );
};

export default Header;
