import {
  Card,
  ClickAwayListener,
  CardContent,
  Grid,
  ListItem,
  ListItemIcon,
  ListItemText,
  Popover,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { AppsOutlined } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { OPEN_PLATFORM, SET_PLATFORM } from '../../../../store/actions.js';
import usePlatform from '../../../../hooks/usePlatform.js';
import { filterColor } from '../../../../constants/initial.js';

const useStyles = makeStyles((theme) => ({
  popover: {
    '& .MuiPopover-paper': {
      borderRadius: 4,
    },
  },
  listRoot: {
    padding: 0,
  },
  cardRoot: {
    width: 320,
  },
  cardTitle: {
    fontSize: 15,
    fontWeight: 600,
    padding: '0 10px',
    marginBottom: 10,
  },
  cardContent: {
    padding: 10,

    '&:last-child': {
      paddingBottom: 15,
    },
  },
  listItem: {
    padding: '5px 10px',
    '& .MuiListItemIcon-root': {
      minWidth: 30,
    },
    '& .MuiTypography-root': {
      overflow: ' hidden',
      display: '-webkit-box',
      WebkitLineClamp: 2,
      WebkitBoxOrient: 'vertical',
    },
  },
  icon: {
    filter: filterColor,
  },
}));

const PopoverApp = (props) => {
  const classes = useStyles();
  const { open, anchorEl, onClose } = props;
  const dispatch = useDispatch();
  const { platforms } = usePlatform();
  const { platform: selectedPlatform } = useSelector((state) => state.platform);

  const handleProjectClick = async (platform) => {
    dispatch({ type: SET_PLATFORM, platform });
    dispatch({ type: OPEN_PLATFORM });
  };

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      className={classes.popover}
    >
      <ClickAwayListener onClickAway={onClose}>
        <Card className={classes.cardRoot}>
          <CardContent className={classes.cardContent}>
            <Typography className={classes.cardTitle} color="textSecondary">
              Apps
            </Typography>
            <Grid container spacing={1}>
              {!!platforms &&
                platforms?.map((platform, index) => (
                  <Grid item xs={12} key={index}>
                    <ListItem
                      className={classes.listItem}
                      alignItems="center"
                      onClick={() => handleProjectClick(platform)}
                      button
                      selected={selectedPlatform.id === platform.id}
                    >
                      <ListItemIcon>
                        <ListItemIcon>
                          {platform.icon ? (
                            <img className={classes.icon} width="22" height="22" src={platform.icon} alt="" />
                          ) : (
                            <AppsOutlined />
                          )}
                        </ListItemIcon>
                      </ListItemIcon>
                      <ListItemText primary={platform.platform_name} />
                    </ListItem>
                  </Grid>
                ))}
            </Grid>
          </CardContent>
        </Card>
      </ClickAwayListener>
    </Popover>
  );
};

export default PopoverApp;
