export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const MENU_OPEN = '@customization/MENU_OPEN';
export const MENU_TYPE = '@customization/MENU_TYPE';
export const THEME_LOCALE = '@customization/THEME_LOCALE';
export const THEME_RTL = '@customization/THEME_RTL';
export const SNACKBAR_OPEN = '@snackbar/SNACKBAR_OPEN';
export const SNACKBAR_CLOSE = '@snackbar/SNACKBAR_CLOSE';
export const ACCOUNT_INITIALISE = 'ACCOUNT_INITIALISE';
export const FOLDER_CHANGE = '@folder/CHANGE';
export const SELECTED_FOLDER_CHANGE = '@folder/SELECTED_FOLDER_CHANGE';
export const AUTH_LOGIN = '@auth/LOGIN';
export const HOME_CHANGE = '@home/CHANGE';
export const PROJECT_CHANGE = '@project/CHANGE';
export const FLOATING_MENU_CHANGE = '@floatingMenu/CHANGE';
export const LOADING_CHANGE = '@loading/CHANGE';
export const VIEW_CHANGE = '@view/CHANGE';
export const CONFIRM_CHANGE = '@confirm/CHANGE';
export const DOCUMENT_CHANGE = '@document/CHANGE';
export const NEWS_CHANGE = '@news/CHANGE';
export const TASK_CHANGE = '@task/CHANGE_TASK';
export const APP_CHANGE = '@app/CHANGE_APP';
export const SELECTED_APP_CHANGE = '@app/SELECTED_APP_CHANGE';
export const METADATA = '@share/METADATA';
export const UPLOAD_OPEN = '@upload/UPLOAD_OPEN';
export const UPLOAD_CLOSE = '@upload/UPLOAD_CLOSE';
export const OPEN_PLATFORM = '@platform/OPEN_PLATFORM';
export const CLOSE_PLATFORM = '@platform/CLOSE_PLATFORM';
export const SET_PLATFORM = '@platform/SET_PLATFORM';
export const SET_ROLE = '@platform/SET_ROLE';
export const CLEAR_PLATFORM = '@platform/CLEAR_PLATFORM';
export const ADVANCED_UPLOAD_OPEN = '@upload/ADVANCED_UPLOAD_OPEN';
export const ADVANCED_UPLOAD_CLOSE = '@upload/ADVANCED_UPLOAD_CLOSE';
export const ASSISTANT_CHANGE = '@app/ASSISTANT_CHANGE';
export const SELECTED_ASSISTANT = '@app/SELECTED_ASSISTANT';
