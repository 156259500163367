import { AppBar, ClickAwayListener, CssBaseline, Toolbar, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Widget } from '@xauuu/chat-widget';
import clsx from 'clsx';
import React from 'react';
import { useSelector } from 'react-redux';
import config from '../../config/config.js';
import useAuth from '../../hooks/useAuth.js';
import ConfirmPopup from '../../views/ConfirmPopup';
import FloatingMenu from '../../views/FloatingMenu';
import AccountModal from '../../views/Process/Account/Detail';
import DepartmentModal from '../../views/Process/Department/Detail';
import UserGroupModal from '../../views/Process/UserGroup/Detail';
import UserGroupMenuItemModal from '../../views/Process/UserGroupMenuItem/Detail';
import { drawerWidth } from './../../constants';
import useLoading from './../../hooks/useLoading';
import Header from './Header';
import Loading from './Loading';
import Sidebar from './Sidebar';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  toolbar: theme.mixins.toolbar,
  content: {
    width: '100%',
    minHeight: '100vh',
    flexGrow: 1,
    /*padding: theme.spacing(3),*/
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.up('md')]: {
      // marginLeft: `calc(-${drawerWidth}px + 90px )`,
      width: `calc(100% - ${drawerWidth}px)`,
    },
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  main: {
    // padding: '20px 40px',
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(3),
    },
  },
  headerRoot: {
    zIndex: 1,
    backgroundColor: '#fff',
    boxShadow: '0 1px 0 0 #F2F2F2',
    transition: theme.transitions.create('width'),
    // width: `calc(100% - ${90}px)`,
    '& .MuiToolbar-regular': {
      gap: '20px',
      padding: '0 40px 0 50px',
    },
  },
  headerOpen: {
    width: `calc(100% - ${drawerWidth}px)`,
  },
}));

const MainLayout = ({ children }) => {
  const classes = useStyles();
  const theme = useTheme();
  const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const { loading } = useLoading();

  const { documentType } = useSelector((state) => state.document);
  const { user } = useAuth();
  const { assistants } = useSelector((state) => state.app);

  const renderDetailDialog = () => {
    switch (documentType) {
      case 'account':
        return <AccountModal />;
      case 'department':
        return <DepartmentModal />;
      case 'usergroup':
        return <UserGroupModal />;
      case 'usergroupmenuitem':
        return <UserGroupMenuItemModal />;
      default:
        return null;
    }
  };

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  React.useEffect(() => {
    setDrawerOpen(false);
  }, [matchUpMd]);

  return (
    <React.Fragment>
      <div className={classes.root}>
        <CssBaseline />
        <ClickAwayListener onClickAway={() => setDrawerOpen(false)}>
          <div>
            <AppBar position="fixed" className={clsx(classes.headerRoot)}>
              <Toolbar>
                <Header drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} />
              </Toolbar>
            </AppBar>
            <Sidebar drawerOpen={drawerOpen} drawerToggle={handleDrawerToggle} setDrawerOpen={setDrawerOpen} />
          </div>
        </ClickAwayListener>
        <main className={clsx(classes.content)}>
          <div className={classes.toolbar} />
          <div className={classes.main}>{children}</div>
          <FloatingMenu />
          <Widget
            titleAvatar="https://egp.vn/favicon.ico"
            title="Welcome to EGP Chatbot"
            subtitle="Ask me anything"
            assistantId={'asst_liymjlkvxFthDaIZCqScOGd2'}
            apiUrl={'https://decisions.truebpm.vn'}
            sessionId={user?.session_id}
            primaryColor="#113d64"
            assistants={assistants}
            imagePreview={true}
          />
          {renderDetailDialog()}
        </main>
        {loading && <Loading />}
        <ConfirmPopup />
      </div>
      <center style={{ marginBottom: 20 }}>
        © {new Date().getFullYear()} Bản quyền thuộc về {config.title}
      </center>
    </React.Fragment>
  );
};

export default MainLayout;
