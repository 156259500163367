export const view = {
  floating: {},

  user: {
    list: {
      create: 'EGP_ORGANISATION_HOME_CREATE_NEW_USER_MENU_BUTTON',
    },
    detail: {
      save: 'EGP_ORGANISATION_HOME_UPDATE_USER_FORM_BUTTON',
    },
  },
  department: {
    list: {
      create: 'EGP_ORGANISATION_HOME_CREATE_NEW_DEPARTMENT_MENU_BUTTON',
      update: 'EGP_ORGANISATION_HOME_UPDATE_MENU_BUTTON',
    },
    detail: {
      adduser: 'EGP_ORGANISATION_HOME_ADD_USER_FORM_BUTTON',
      removeaccount: 'EGP_ORGANISATION_HOME_REMOVE_USER_FORM_BUTTON',
      save: 'EGP_ORGANISATION_HOME_UPDATE_DEPARTMENT_FORM_BUTTON',
    },
  },

  ugroup: {
    list: {
      create: `EGP_ORGANISATION_HOME_CREATE_NEW_UGROUP_MENU_BUTTON`,
    },
    detail: {
      update: 'EGP_ORGANISATION_HOME_UPDATE_UGROUP_FORM_BUTTON',
    },
  },
  assessment: {
    list: {
      sync: 'EGP_EGP_INTERNAL_AUDIT_ASSESSMENT_DEFAULT_SYNC_MENU_BUTTON',
    },
  },
  risk: {
    list: {
      create: 'EGP_EGP_INTERNAL_AUDIT_RISK_DEFAULT_CREATE_MENU_BUTTON',
    },
  },
};
