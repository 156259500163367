import React, { createContext, useEffect } from 'react';
import { useState } from 'react';
import AppService from '../services/api/Menu/app.js';

const PlatformContext = createContext();

export const PlatformProvider = ({ children }) => {
  const [platforms, setPlatforms] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const [platformRes] = await Promise.all([AppService.getPlatforms()]);
      setPlatforms(platformRes);
    }
    fetchData();
  }, []);

  return <PlatformContext.Provider value={{ platforms }}>{children}</PlatformContext.Provider>;
};

export default PlatformContext;
