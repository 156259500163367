import { Box, Divider, LinearProgress, Typography } from '@mui/material';
import React from 'react';
import Dropzone, { useDropzone } from 'react-dropzone';
import { useDispatch } from 'react-redux';
import { openSnackBarTop } from '../../../utils/helper.js';

const Step2 = (props) => {
  const { classes, type, selectedFiles, setSelectedFile, isUploading, progresspercent } = props;
  const dispatch = useDispatch();

  const [fileType, setFileType] = React.useState(type);

  const { getRootProps, getInputProps } = useDropzone({
    accept: fileType,
    onDrop,
  });

  function onDrop(files) {
    if (files[0].size / 1024 / 1024 > 5) {
      openSnackBarTop('error', 'Dung lượng file không được quá 5MB', dispatch);
      return;
    }
    setSelectedFile(files);
  }

  React.useEffect(() => {
    switch (type?.toLowerCase()) {
      case 'csv':
        setFileType('.csv');
        break;
      case 'xlsx':
        setFileType('.xls,.xlsx');
        break;
      default:
        setFileType('image/*');
        break;
    }
  }, [type]);

  return (
    <div className={classes.paper}>
      <Typography variant="h6">Tải file lên</Typography>
      <Divider className={classes.divider} />
      {!isUploading && (
        <Dropzone multiple={false}>
          {() => (
            <section>
              <div {...getRootProps({ className: classes.dropzone })}>
                <input {...getInputProps()} />
                {selectedFiles.length && selectedFiles[0].name ? (
                  <div className={classes.fileName}>{selectedFiles.length && selectedFiles[0].name}</div>
                ) : (
                  'Kéo thả file hoặc bấm vào đây để bắt đầu chọn'
                )}
              </div>
            </section>
          )}
        </Dropzone>
      )}
      {isUploading && (
        <Box>
          <Box>
            <Typography variant="h4">Đang tải lên</Typography>
          </Box>
          <Box display="flex" alignItems="center" className={classes.progress}>
            <Box width="100%" mr={1}>
              <LinearProgress variant="determinate" value={progresspercent} />
            </Box>
            <Box minWidth={35}>
              <Typography variant="body2" color="textSecondary">{`${progresspercent}%`}</Typography>
            </Box>
          </Box>
        </Box>
      )}
    </div>
  );
};

export default Step2;
