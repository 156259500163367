import React, { createContext } from 'react';
import { PROJECT_CHANGE, APP_CHANGE } from '../store/actions';
import { apiEndpoints } from '../constants';
import axiosInstance from '../services/axios';
import { useDispatch } from 'react-redux';

const ProjectContext = createContext({
  getProjects: () => Promise.resolve(),
});

export const ProjectProvider = ({ children }) => {
  const dispatch = useDispatch();

  function getProjects(id) {
    axiosInstance.post(apiEndpoints.get_project_list, { outputtype: 'RawJson', app_id: id }).then((response) => {
      if (response.status === 200 && response.data.return === 200) {
        const { data: projects } = response.data;
        if (projects.length > 0) projects[0].selected = true;

        dispatch({
          type: PROJECT_CHANGE,
          projects,
        });
      }
    });
  }

  function getApps(platform_id) {
    axiosInstance.post(apiEndpoints.get_app_list, { platform_id }).then((response) => {
      if (response.status === 200 && response.data.return === 200) {
        const { list: apps } = response.data;
        dispatch({
          type: APP_CHANGE,
          apps,
        });
      }
    });
  }

  const getAppSetting = async (platform_id) => {
    return axiosInstance.post(apiEndpoints.get_app_setting_list, { platform_id }).then((response) => {
      if (response.status === 200 && response.data.return === 200) {
        const { list: apps } = response.data;
        dispatch({
          type: APP_CHANGE,
          apps,
        });
      } else return [];
    });
  };

  const getAppSettingList = async () => {
    return axiosInstance.post(apiEndpoints.get_menu_app_setting, {}).then((response) => {
      if (response.status === 200 && response.data.return === 200) {
        const { list } = response.data;
        return list;
      } else return [];
    });
  };

  const getPlatform = async () => {
    return axiosInstance.post(apiEndpoints.get_platform_list, {}).then((response) => {
      if (response.status === 200 && response.data.return === 200) {
        const { list } = response.data;
        return list;
      } else return [];
    });
  };

  const getAssistants = async (project_id) => {
    return axiosInstance.post(apiEndpoints.get_assistant_list, { project_id }).then((response) => {
      if (response.status === 200 && response.data.return === 200) {
        const { list } = response.data;
        return list;
      } else return [];
    });
  };

  return (
    <ProjectContext.Provider
      value={{ getProjects, getApps, getAppSetting, getAppSettingList, getPlatform, getAssistants }}
    >
      {children}
    </ProjectContext.Provider>
  );
};

export default ProjectContext;
