import React, { useState, useEffect } from 'react';

import { IntlProvider } from 'react-intl';
import { useSelector } from 'react-redux';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { JWTProvider } from '../contexts/JWTContext';
import { FolderProvider } from '../contexts/FolderContext';
import { ProjectProvider } from '../contexts/ProjectContext';
import { LoadingProvider } from '../contexts/LoadingContext';
import { ViewProvider } from '../contexts/ViewContext';
import { ConfirmPopupProvider } from '../contexts/ConfirmPopupContext';
import theme from './../themes';
import Routes from '../Routes';
import NavigationScroll from './NavigationScroll';
import Snackbar from '../component/Snackbar';
import { create } from 'jss';
import rtl from 'jss-rtl';
import { StylesProvider, jssPreset } from '@mui/styles';
import { TaskProvider } from '../contexts/TaskContext.js';
import { ChartProvider } from './../contexts/ChartContext';
import { ShareProvider } from './../contexts/ShareContext';
import Upload from '../views/FloatingMenu/index.js';
import { ErrorBoundary } from 'react-error-boundary';
import Errors from '../views/Pages/Error/index.js';
import { PlatformProvider } from '../contexts/PlatformContext.js';
import PlatformDialog from '../component/PlatformDialog/index.js';
import AdvancedUpload from './../component/AdvancedUpload/index';

function loadLocaleData(locale) {
  switch (locale) {
    case 'fr':
      return import('./../compiled-lang/fr.json');
    case 'ro':
      return import('./../compiled-lang/ro.json');
    case 'zh':
      return import('./../compiled-lang/zh.json');
    default:
      return import('./../compiled-lang/en.json');
  }
}

// Configure JSS
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
const App = () => {
  const customization = useSelector((state) => state.customization);
  const [messages, setMessages] = useState();

  useEffect(() => {
    loadLocaleData(customization.locale).then((d) => {
      setMessages(d.default);
    });
  }, [customization]);

  return (
    <React.Fragment>
      {messages && (
        <IntlProvider locale={customization.locale} defaultLocale="en" messages={messages}>
          <CssBaseline />
          <NavigationScroll>
            <StylesProvider jss={jss}>
              <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme(customization)}>
                  <ErrorBoundary FallbackComponent={Errors}>
                    <LoadingProvider>
                      <ConfirmPopupProvider>
                        <JWTProvider>
                          <PlatformProvider>
                            <ViewProvider>
                              <ProjectProvider>
                                <FolderProvider>
                                  <TaskProvider>
                                    <ChartProvider>
                                      <ShareProvider>
                                        <Routes />
                                        <Snackbar />
                                        <Upload />
                                        <AdvancedUpload />
                                        <PlatformDialog />
                                      </ShareProvider>
                                    </ChartProvider>
                                  </TaskProvider>
                                </FolderProvider>
                              </ProjectProvider>
                            </ViewProvider>
                          </PlatformProvider>
                        </JWTProvider>
                      </ConfirmPopupProvider>
                    </LoadingProvider>
                  </ErrorBoundary>
                </ThemeProvider>
              </StyledEngineProvider>
            </StylesProvider>
          </NavigationScroll>
        </IntlProvider>
      )}
    </React.Fragment>
  );
};

export default App;
