import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { OutTable, ExcelRenderer } from 'react-excel-renderer';
import './styles.css';

const PreviewExcel = ({ open, handleClose, file }) => {
  const [resp, setResp] = useState({
    cols: [],
    rows: [],
  });

  useEffect(() => {
    if (!file && !open) return;

    ExcelRenderer(file, (err, resp) => {
      if (err) {
        console.log(err);
      } else {
        setResp({
          cols: resp.cols,
          rows: resp.rows,
        });
      }
    });

    return () => {
      setResp({
        cols: [],
        rows: [],
      });
    };
  }, [open, file]);

  return (
    <Dialog
      //   fullWidth={true}
      maxWidth={'lg'}
      open={open}
      onClose={handleClose}
      sx={{
        zIndex: 9999,
        '& .MuiDialog-container': {
          alignItems: 'center',
          '& .MuiDialogTitle-root': {
            textAlign: 'center',
            background: 'transparent',
          },
          '& .MuiDialog-paper': {
            borderRadius: '4px',
          },
        },
      }}
    >
      <DialogTitle>Preview</DialogTitle>
      <DialogContent>
        <OutTable data={resp.rows} columns={resp.cols} tableClassName="ExcelTable2007" tableHeaderRowClass="heading" />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default PreviewExcel;
