export const documentType = {
  assessment: {
    title: 'Đánh giá rủi ro',
    type: 'assessment',
  },
  risk: {
    title: 'Quản lý rủi ro',
    type: 'risk',
  },
  project: {
    title: 'Quản lý dự án',
    type: 'project',
  },
  issueReleased: {
    title: 'Quản lý vấn đề đã phát hành',
    type: 'issueReleased',
  },
  issueUnReleased: {
    title: 'Quản lý vấn đề chưa phát hành',
    type: 'issueUnReleased',
  },
};
