import React from 'react';
import { List } from '@mui/material';
import NavItem from './../NavItem';

const NavGroup = (props) => {
  const { item, drawerToggle, drawerOpen } = props;
  return (
    <List style={{ overflow: 'inherit' }}>
      <NavItem item={item} level={1} drawerToggle={drawerToggle} drawerOpen={drawerOpen} />
    </List>
  );
};

export default NavGroup;
