export const accountActions = {
  list_active_user: 'EGP_ORGANISATION_HOME_MANAGE_USER',
};
export const departmentActions = {
  list_active_department: 'EGP_ORGANISATION_HOME_MANAGE_DEPARTMENT',
};
export const usergroupAction = {
  list: 'EGP_ORGANISATION_HOME_MANAGE_USERGROUP',
};
export const permissionAction = {
  list: 'EGP_ORGANISATION_HOME_MANAGE_PERMISSION',
};
export const appConfig = {
  list: 'EGP_GLOBAL_SEETING_APPCONFIG_OPEN_APP_LIST',
};

export const assessmentAction = {
  list: 'EGP_EGP_INTERNAL_AUDIT_ASSESSMENT_DEFAULT_OPEN_ASSESSMENT_LIST',
};
export const projectAction = {
  list: 'EGP_PROJECT_DEFAULT_OPEN_LIST_VIEW',
};
export const riskAction = {
  list: 'EGP_EGP_INTERNAL_AUDIT_RISK_DEFAULT_OPEN_LIST',
};
export const issueReleasedAction = {
  list: 'EGP_EGP_INTERNAL_AUDIT_ISSUE_DEFAULT_OPEN_RELEASED_LIST',
};
export const issueUnReleasedAction = {
  list: 'EGP_EGP_INTERNAL_AUDIT_ISSUE_DEFAULT_OPEN_UNRELEASED_LIST',
};
