import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Slide, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ADVANCED_UPLOAD_CLOSE } from '../../store/actions';
import { makeStyles } from '@mui/styles';
import Step1 from './Steps/step1.js';
import Step2 from './Steps/step2.js';
import PreviewExcel from '../PreviewExcel/index.js';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import { storage } from '../../services/firebase.js';
import { openSnackBarTop } from '../../utils/helper.js';
import { ConnectorIcon, FileIcon } from '../Icons';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: 9998,
    '& .MuiDialog-container': {
      alignItems: 'start',
      marginTop: 50,
      '& .MuiDialogTitle-root': {
        textAlign: 'center',
        background: 'transparent',
        padding: theme.spacing(1, 3),
        borderBottom: 'solid 1px',
        borderBottomColor: theme.palette.primary.main,
      },
      '& .MuiDialogContent-root': {
        paddingTop: 10,
        minHeight: 200,
      },
      '& .MuiDialog-paper': {
        borderRadius: 4,
        width: 700,
        maxWidth: 800,
      },
    },
  },
  paper: {},
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  dropzone: {
    textAlign: 'center',
    padding: theme.spacing(4, 3),
    border: '3px dashed #eeeeee',
    backgroundColor: '#fafafa',
    color: '#6e6e6e',
    cursor: 'pointer',
    marginBottom: '20px',
    fontSize: '15px',
  },
  progress: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  file_name: {
    wordWrap: 'break-word',
  },
}));

const data = [
  {
    id: 'file',
    title: 'File',
    children: [
      {
        id: 'csv',
        title: 'CSV',
        icon: FileIcon,
      },
      {
        id: 'excel',
        title: 'Excel',
        icon: FileIcon,
      },
    ],
  },
  {
    id: 'egp_connector',
    title: 'EGP Connector',
    children: [
      {
        id: 'bank',
        title: 'Bank Data',
        icon: ConnectorIcon,
      },
      {
        id: 'risk',
        title: 'Risk Data',
        icon: ConnectorIcon,
      },
    ],
  },
];

const informationData = [
  {
    id: 'csv',
    title: 'Import CSV File',
    description: 'This is template import',
    type: 'csv',
    files: [
      {
        file_name: 'sample.csv',
        file_url: '',
        file_type: 'xlsx',
      },
    ],
  },
  {
    id: 'excel',
    title: 'Import Excel File',
    description: 'This is template import for Excel',
    type: 'xlsx',

    files: [
      {
        file_name: 'Data Import.xlsx',
        file_url:
          'https://firebasestorage.googleapis.com/v0/b/enterprise-governance-platform.appspot.com/o/Templates%2FData%20Import.xlsx?alt=media&token=37813f38-0147-47d3-adec-7fdb8a25165c',
        file_type: 'xlsx',
      },
    ],
  },
  {
    id: 'bank',
    title: 'Connector to Bank Transaction Data',
    list: ['Connection Type: Database', 'Support Database: MSSQL, MySQL, Oracle & ODBC'],
  },
  {
    id: 'risk',
    title: 'Connector to Risk Management System or GRC System',
    list: ['Connection Type: Database', 'Support Database: MSSQL, MySQL, Oracle & ODBC'],
  },
];

const AdvancedUpload = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { open, title, onSuccess, folder, isPreview } = useSelector((state) => state.advancedUpload);
  const [information, setInformation] = useState(null);
  const [activeStep, setActiveStep] = useState(1);
  const [treeData, setTreeData] = useState({
    expanded: ['file'],
    selected: '',
  });

  const [selectedFiles, setSelectedFile] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [progresspercent, setProgresspercent] = useState(0);
  const [openPreview, setOpenPreview] = useState(false);

  const handleNext = () => {
    if (activeStep === 2) uploadToStorage();
    else setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  async function uploadToStorage() {
    const fileData = {};
    setIsUploading(true);
    const storageRef = ref(storage, `Upload/${folder}/${new Date().getTime()}_${selectedFiles[0].name}`);
    const uploadTask = uploadBytesResumable(storageRef, selectedFiles[0]);
    fileData.file_name = selectedFiles[0].name;

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
        setProgresspercent(progress);
      },
      (error) => {
        openSnackBarTop('Tải lên thất bại', 'error');
        setIsUploading(false);
        setProgresspercent(0);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          fileData.url = downloadURL;
          setSelectedFile([]);
          setProgresspercent(0);
          onSuccess(fileData);
          setIsUploading(false);
          handleClose();
        });
      }
    );
  }

  const handleClose = () => {
    dispatch({ type: ADVANCED_UPLOAD_CLOSE });
    setInformation(null);
    setActiveStep(1);
    setTreeData({
      expanded: ['file'],
      selected: '',
    });
    setSelectedFile([]);
    setIsUploading(false);
    setProgresspercent(0);
    setOpenPreview(false);
  };

  const handleOpenPreview = () => setOpenPreview(true);
  const handleClosePreview = () => setOpenPreview(false);

  const handleNodeSelect = (e, nodes) => {
    setTreeData((prevState, props) => {
      const isNodeExpanded = prevState.expanded.includes(nodes.id);

      return {
        expanded: isNodeExpanded
          ? prevState.expanded.filter((id) => id !== nodes.id)
          : [...prevState.expanded, nodes.id],
        selected: nodes.id,
      };
    });
    getInformation(nodes.id);
  };

  const getInformation = (id) => {
    const info = informationData.find((item) => item.id === id);
    setInformation(info);
  };

  return (
    <Dialog open={open} className={classes.root} TransitionComponent={Transition} keepMounted>
      <DialogTitle component="div">
        <Typography variant="h6">{title || 'Import'}</Typography>
      </DialogTitle>
      <DialogContent>
        {activeStep === 1 && (
          <Step1 data={data} information={information} handleNodeSelect={handleNodeSelect} treeData={treeData} />
        )}
        {activeStep === 2 && information.type && (
          <>
            {/* <PreviewExcel open={openPreview} handleClose={handleClosePreview} file={selectedFiles[0]} /> */}
            <Step2
              classes={classes}
              type={information.type}
              selectedFiles={selectedFiles}
              setSelectedFile={setSelectedFile}
              isUploading={isUploading}
              progresspercent={progresspercent}
            />
          </>
        )}
      </DialogContent>
      <DialogActions>
        {activeStep === 1 ? (
          <Button variant="outlined" onClick={handleClose}>
            Close
          </Button>
        ) : (
          <Button variant="outlined" onClick={handleBack}>
            Back
          </Button>
        )}
        {/* {activeStep === 2 && selectedFiles.length > 0 && isPreview && (
          <Button variant="contained" color="primary" onClick={handleOpenPreview}>
            Preview
          </Button>
        )} */}
        <Button variant="contained" color="primary" onClick={handleNext}>
          {activeStep === 2 ? 'Import' : 'Next'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AdvancedUpload;
