import * as actionTypes from './actions';

export const initialState = {
  provinces: [],
  genders: [],
  weekday: [],
  career_topic_list: [],
  career_category_list: [],
  degree_list: [],
  dimensions: [],
  activityTypes: [],
};

const metadataReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.METADATA:
      return {
        ...state,
        genders: action.genders,
        provinces: action.provinces,
        weekday: action.weekday,
        dimensions: action.dimensions,
        activityTypes: action.activityTypes,
      };
    default:
      return state;
  }
};

export default metadataReducer;
