import * as actionTypes from './actions';

const initialState = {
  open: false,
  title: 'Import',
  folder: 'Upload',
  fileType: 'other',
  onSuccess: () => {},
  isPreview: false,
};

const advancedUploadReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADVANCED_UPLOAD_OPEN:
      return {
        ...state,
        open: true,
        title: action.title ? action.title : initialState.title,
        folder: action.folder ? action.folder : initialState.folder,
        fileType: action.fileType ? action.fileType : initialState.fileType,
        onSuccess: action.onSuccess ? action.onSuccess : initialState.onSuccess,
        isPreview: action.isPreview ? action.isPreview : initialState.isPreview,
      };
    case actionTypes.ADVANCED_UPLOAD_CLOSE:
      return {
        ...state,
        open: false,
      };
    default:
      return state;
  }
};

export default advancedUploadReducer;
