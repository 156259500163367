import * as actionTypes from './actions';

export const initialState = {
  isOpen: false,
  platform: {},
  role: {},
};

const platformReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.OPEN_PLATFORM:
      return {
        ...state,
        isOpen: true,
      };
    case actionTypes.CLOSE_PLATFORM:
      return {
        ...state,
        isOpen: false,
      };
    case actionTypes.SET_PLATFORM:
      return {
        ...state,
        platform: action.platform,
        role: {},
      };
    case actionTypes.SET_ROLE:
      return {
        ...state,
        role: action.role,
      };
    case actionTypes.CLEAR_PLATFORM:
      return {
        ...state,
        platform: {},
        role: {},
      };
    default:
      return state;
  }
};

export default platformReducer;
