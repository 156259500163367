import { ChevronRight, ExpandMore, Folder } from '@mui/icons-material';
import { TreeView } from '@mui/lab';
import { Grid, Stack, Typography } from '@mui/material';
import React from 'react';
import StyledTreeItem from '../../StyledTreeItem/index.js';
import FileDocument from '../../File/index.js';

const Step1 = (props) => {
  const { data, information, handleNodeSelect, treeData } = props;
  const renderTree = (nodes) => (
    <StyledTreeItem
      key={nodes.id}
      nodeId={String(nodes.id)}
      labelText={nodes?.title}
      color="#1a73e8"
      bgColor="#e8f0fe"
      labelIcon={nodes.icon ? nodes.icon : Folder}
      onClick={(e) => handleNodeSelect(e, nodes)}
    >
      {Array.isArray(nodes.children) ? nodes.children.map((node) => renderTree(node)) : null}
    </StyledTreeItem>
  );
  return (
    <Grid container spacing={2}>
      <Grid item xs={5}>
        <Typography variant="body1">Data Source</Typography>
        <TreeView
          defaultCollapseIcon={<ExpandMore />}
          defaultExpandIcon={<ChevronRight />}
          expanded={treeData.expanded}
          selected={treeData.selected}
        >
          {data.map((item) => renderTree(item))}
        </TreeView>
      </Grid>
      <Grid item xs={7}>
        <Typography variant="body1">Description</Typography>
        {information && (
          <Stack spacing={2}>
            <Stack>
              <Typography variant="subtitle1">{information.title}</Typography>
              {information.description && <Typography variant="subtitle2">{information.description}</Typography>}
              {information.list && information.list.length > 0 && (
                <ul style={{ margin: 0 }}>
                  {information.list.map((item, index) => (
                    <li key={index}>
                      <Typography variant="subtitle2">{item}</Typography>
                    </li>
                  ))}
                </ul>
              )}
            </Stack>
            <FileDocument files={information.files} xs={6} />
          </Stack>
        )}
      </Grid>
    </Grid>
  );
};

export default Step1;
