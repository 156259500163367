import React from 'react';
import { useDialog50Styles } from '../../utils/style.js';
import { Box, Grid, Link, Tooltip, Typography } from '@mui/material';
import iconCloud from '../../assets/svgs/icon-cloud.svg';
import iconPDF from '../../assets/svgs/icon-pdf.svg';
import iconDocx from '../../assets/svgs/icon-docx.svg';
import iconXlsx from '../../assets/svgs/icon-xlsx.svg';
import iconPptx from '../../assets/svgs/icon-pptx.svg';

const FileDocument = (props) => {
  const { files, xs } = props;
  const classes = useDialog50Styles();

  const imageMap = {
    pdf: iconPDF,
    docx: iconDocx,
    xlsx: iconXlsx,
    pptx: iconPptx,
  };

  return (
    <Grid container spacing={1} style={{ margin: 0 }}>
      {/* <Grid item xs={2} style={{ border: 'dashed 2px #c8c8c8' }}>
        <Box className={classes.itemFile}>
          <img src={iconCloud} alt="" className={classes.iconUpload} />
          <Typography className={classes.fileName}>Tải File lên</Typography>
        </Box>
      </Grid> */}
      {files?.map((file, index) => (
        <Grid item xs={xs} key={index}>
          <Link href={file.file_url} target="_blank" className={classes.itemFile}>
            <img src={imageMap[file.file_type]} alt="icon" />
            <Tooltip title={file.file_name}>
              <Typography variant="subtitle2" className={classes.fileName}>
                <img src={iconCloud} alt="" />
                {file.file_name}
              </Typography>
            </Tooltip>
          </Link>
        </Grid>
      ))}
    </Grid>
  );
};

export default FileDocument;
