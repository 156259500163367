import * as actionTypes from './actions';

export const initialState = {
  apps: [],
  selectedApp: {},
  platform: {},
  assistants: [],
  selectedAssistant: {},
};

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.APP_CHANGE:
      return {
        ...state,
        apps: action.apps,
      };
    case actionTypes.SELECTED_APP_CHANGE:
      return {
        ...state,
        selectedApp: action.app,
        selectedAssistant: {},
      };
    case actionTypes.OPEN_PLATFORM:
      return {
        ...state,
        platform: action.platform,
      };
    case actionTypes.ASSISTANT_CHANGE:
      return {
        ...state,
        assistants: action.assistants,
      };
    case actionTypes.SELECTED_ASSISTANT:
      return {
        ...state,
        selectedAssistant: action.assistant,
      };
    default:
      return state;
  }
};

export default appReducer;
