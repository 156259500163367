import { SNACKBAR_OPEN } from '../store/actions.js';

export const downloadFile = (url) => {
  const link = document.createElement('a');
  link.href = url;
  link.target = '_blank';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const popupWindow = (url, title) => {
  var width = window.outerWidth ? window.outerWidth : document.documentElement.clientWidth;
  var height = window.outerHeight ? window.outerHeight : document.documentElement.clientHeight;
  var w = width * 1;
  var h = height * 0.7;
  var left = width / 2 - w / 2;
  var top = height / 2 - h / 2;
  var newWindow = window.open(
    url,
    title,
    'scrollbars=yes, width=' + w + ', height=' + h + ', top=' + top + ', left=' + left
  );
  // Puts focus on the newWindow
  if (window.focus) {
    newWindow.focus();
  }
  return newWindow;
};

export const getStyleStatus = (status) => {
  if (status?.includes('SUBMIT')) return { color: '#079449', backgroundColor: '#EFFFF4' };
  if (status?.includes('COMPLETE')) return { color: '#0B74E5', backgroundColor: '#F4F5F6' };
  if (status?.includes('REVIEW')) return { color: '#8B97A1', backgroundColor: '#F4F5F6' };
  if (status?.includes('OVERDUE')) return { color: '#D93843', backgroundColor: '#F4F5F6' };
  if (status?.includes('REJECT')) return { color: '#FF9F41', backgroundColor: '#F4F5F6' };
  if (status?.includes('APPROVE')) return { color: '#2DC26D', backgroundColor: '#F4F5F6' };
  if (status?.includes('DRAFT')) return { color: '#E36301', backgroundColor: '#FFF5EB' };
  return { color: '#000', backgroundColor: '#F4F5F6' };
};

export const openSnackBarTop = (type, message, dispatch) => {
  dispatch({
    type: SNACKBAR_OPEN,
    open: true,
    variant: 'alert',
    message: message,
    alertSeverity: type,
    anchorOrigin: {
      vertical: 'top',
      horizontal: 'center',
    },
  });
};

export const isValidEmail = (email) => {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(email);
};

export const splitImageDrive = (image_url) => {
  try {
    var index = image_url.indexOf('drive.google.com');
    if (index > -1) {
      index = image_url.indexOf('id=');
      if (index > -1) {
        return 'https://drive.google.com/uc?export=view&id=' + image_url.split('id=')[1];
      } else {
        return 'https://drive.google.com/uc?export=view&id=' + image_url.split('/d/')[1].split('/view')[0];
      }
    } else return image_url;
  } catch {
    return image_url;
  }
};

export function buildTree(entities, parentId = null, visited = new Set()) {
  if (!entities) return [];

  const result = [];

  entities.forEach((item) => {
    if (
      !visited.has(item.id) &&
      ((item.parent_id && item.parent_id === parentId) || (!item.parent_id && parentId === null))
    ) {
      visited.add(item.id);

      const children = buildTree(entities, item.id, visited);
      if (children.length > 0) {
        item.children = children;
      }
      result.push(item);
    }
  });

  return result;
}

export function buildTreeRisk(entities, parentId = null, visited = new Set()) {
  if (!entities) return [];

  const result = [];

  entities.forEach((item) => {
    if (
      !visited.has(item.id) &&
      ((item.parent && item.parent.id === parentId) || (!item.parent && parentId === null))
    ) {
      visited.add(item.id);

      const children = buildTreeRisk(entities, item.id, visited);
      if (children.length > 0) {
        item.children = children;
      }
      result.push(item);
    }
  });

  return result;
}

export const columnNo = {
  field: 'no',
  headerName: '#',
  sortable: false,
  disableColumnMenu: true,
  headerAlign: 'center',
  align: 'center',
  width: 40,
  renderCell: (params) => params.api.getAllRowIds().indexOf(params.id) + 1,
};
