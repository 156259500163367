export const apiEndpoints = {
  authenticate: '/Primary/?FlowAlias=bs_api_user_authenticate&action=api',
  get_project_list: '/Primary/?FlowAlias=bs_menu_api_get_project_list&action=api',
  get_app_list: '/Primary/?FlowAlias=bs_menu_api_get_list_app&action=api',
  get_app_setting_list: '/Primary/?FlowAlias=bs_menu_api_get_list_app_setting&action=api',
  get_platform_list: '/Primary/?FlowAlias=bs_menu_api_get_platform_list&action=api',
  get_role_list_by_platform: '/Primary/?FlowAlias=bs_api_get_user_group_by_platform_id&action=api',
  update_profile_setting: '/Primary/?FlowAlias=bs_api_orgm_update_profile_setting&action=api',
  get_profile_setting: '/Primary/?FlowAlias=bs_api_orgm_get_profile_setting&action=api',
  get_folders: '/Primary/?FlowAlias=bs_menu_api_get_menu_tree&action=api',
  get_metadata: '/Primary/?FlowAlias=bs_api_egp_get_meta_data&action=api',
  get_menu_item_tree_view: '/Primary/?FlowAlias=bs_api_ui_get_menu_item_tree_view_by_company_code&action=api',
  get_menu_item_lookup_tree_view: '/Primary/?FlowAlias=bs_api_ui_get_project_item_lookup_list_by_user_group&action=api',
  get_menu_app_setting: '/Primary/?FlowAlias=bs_api_menu_get_setting_app_by_company_code&action=api',
  update_menu_item_update_lookup:
    '/Primary/?FlowAlias=bs_api_ui_update_menu_project_item_list_by_user_group&action=api',
  get_app_detail: '/Primary/?FlowAlias=bs_api_menu_get_app_detail_by_id&action=api',
  // Account
  get_all_active_account: '/Primary/?FlowAlias=bs_user_api_get_all_active_account_by_page&action=api',
  get_all_deactive_account: '/Primary/?FlowAlias=bs_user_api_get_all_deactive_account_by_page&action=api',
  get_account_detail: '/Primary/?FlowAlias=bs_api_user_get_account_by_id&action=api',
  create_account: '/Primary/?FlowAlias=bs_api_user_create_account_by_host&action=api',
  update_account: '/Primary/?FlowAlias=bs_api_user_update_account_by_id&action=api',
  get_all_account_by_department_and_role_template:
    '/Primary/?FlowAlias=bs_api_user_get_all_acount_by_departmentand_role_template&action=api',
  assign_account_to_dept: '/Primary/?FlowAlias=bs_api_user_api_assign_account_to_department&action=api',
  remove_account_from_dept: '/Primary/?FlowAlias=bs_api_user_api_remove_account_from_department&action=api',
  active_account: '/Primary/?FlowAlias=bs_api_user_active_account&action=api',
  get_account_list_by_process_role: '/Primary/?FlowAlias=bs_api_user_get_list_by_process_role&action=api',
  get_all_task: '/Primary/?FlowAlias=bs_api_process_get_all_task_by_current_user&action=api',
  get_all_department_by_page: '/Primary/?FlowAlias=bs_api_dept_get_all_active_department_by_page&action=api',
  get_department_role_by_group: '/Primary/?FlowAlias=bs_api_dept_get_department_role_by_group_id&action=api',
  get_all_account_list: '/Primary/?FlowAlias=bs_api_orgm_user_get_all_account&action=api',
  reset_password: '/Primary/?FlowAlias=bs_api_orgm_user_reset_password&action=api',
  forgot_password: '/Primary/?FlowAlias=bs_api_user_forget_password&action=api',
  validate_forgot_password: '/Primary/?FlowAlias=bs_api_user_validate_forgot_password_verification&action=api',
  get_user_card_list: '/Primary/?FlowAlias=bs_api_user_get_card_list_by_company_code&action=api',
  get_profile_by_user_group_id: '/Primary/?FlowAlias=bs_api_orgm_get_profile_list_by_user_group_id&action=api',
  get_profile_by_app: '/Primary/?FlowAlias=bs_api_orgm_get_account_by_app&action=api',
  get_profile_cache_by_account_id: '/Primary/?FlowAlias=bs_api_user_get_user_cache_by_account_id&action=api',
  assign_account_to_app: '/Primary/?FlowAlias=bs_api_menu_app_assign_account&action=api',
  remove_account_to_app: '/Primary/?FlowAlias=bs_api_menu_app_remove_account&action=api',
  get_sub_app_list: '/Primary/?FlowAlias=bs_api_menu_app_get_app_sub_list_by_page&action=api',
  //Role template
  get_all_active_role_template:
    '/Primary/?FlowAlias=bs_api_role_template_get_all_active_role_template_by_page&action=api',
  active_role_template: '/Primary/?FlowAlias=bs_api_role_template_active_role_template&action=api',
  get_detail_role_template: '/Primary/?FlowAlias=bs_api_role_template_get_detail_role_template&action=api',
  get_all_inactive_role_template:
    '/Primary/?FlowAlias=bs_api_role_template_get_all_inactive_role_template_by_page&action=api',
  create_role_template: '/Primary/?FlowAlias=bs_api_role_template_create_role_template&action=api',
  update_role_template: '/Primary/?FlowAlias=bs_api_role_template_create_role_template&action=api',
  get_role_tree_data: '/Primary/?FlowAlias=bs_api_process_role_get_tree_data_role&action=api',
  get_all_role_template_by_department_code:
    '/Primary/?FlowAlias=bs_api_dept_get_role_template_by_department_code&action=api',
  get_option_role_template: '/Primary/?FlowAlias=bs_api_role_template_get_optinal_role&action=api',
  add_account_to_group: '/Primary/?FlowAlias=bs_api_user_add_acount_to_group&action=api',
  remove_account_to_group: '/Primary/?FlowAlias=bs_api_user_remove_account_from_group&action=api',
  create_process_role: '/Primary/?FlowAlias=bs_api_process_create_role&action=api',
  update_process_role: '/Primary/?FlowAlias=bs_api_process_update_role&action=api',
  add_user_depart_to_process_role: '/Primary/?FlowAlias=bs_api_process_add_dept_user&action=api',
  remove_user_from_process_role: '/Primary/?FlowAlias=bs_api_process_remove_user&action=api',
  sync_process_role: '/Primary/?FlowAlias=bs_api_process_sync_role_department&action=api',
  remove_dept_from_process_role: '/Primary/?FlowAlias=bs_api_process_remove_dept&action=api',
  get_role_detail: '/Primary/?FlowAlias=bs_api_process_role_get_role_detail_by_role_code&action=api',
  get_process_list: '/Primary/?FlowAlias=bs_get_process_by_app_code&action=api',

  //Department
  sync_group_for_department: '/Primary/?FlowAlias=bs_api_role_template_sync_group_for_department&action=api',
  get_tree_view_data: '/Primary/?FlowAlias=bs_api_dept_get_tree_view_data&action=api',
  deactive_department: '/Primary/?FlowAlias=bs_api_dept_deactive_department&action=api',
  create_department: '/Primary/?FlowAlias=bs_api_dept_create_department&action=api',
  update_department: '/Primary/?FlowAlias=bs_api_dept_update_department&action=api',
  get_department_list: '/Primary/?FlowAlias=bs_api_dept_get_department_list&action=api',
  get_department_type_list: '/Primary/?FlowAlias=bs_api_dept_get_department_type_list&action=api',
  get_department_detail: '/Primary/?FlowAlias=bs_api_dept_get_detail_department_by_name&action=api',
  get_dept_list_by_process_role: '/Primary/?FlowAlias=bs_api_dept_get_list_by_process_code&action=api',
  get_department_deactive_list: '/Primary/?FlowAlias=bs_api_dept_get_all_inactive_department_by_page&action=api',
  get_department_by_account_id:
    '/Primary/?FlowAlias=bs_api_department_get_treeview_department_by_account_id&action=api',

  //User Group
  get_user_group_list_by_page: '/Primary/?FlowAlias=bs_api_orgm_ugroup_get_user_group_list_by_page&action=api',
  get_user_group_detail: '/Primary/?FlowAlias=bs_api_orgm_ugroup_get_user_group_detail&action=api',
  update_user_group_detail: '/Primary/?FlowAlias=bs_api_orgm_ugroup_update_user_group&action=api',
  create_user_group_detail: '/Primary/?FlowAlias=bs_api_orgm_ugroup_create_user_group&action=api',
  update_user_group_account: '/Primary/?FlowAlias=bs_api_orgm_user_update_permistion_group&action=api',
  get_user_group_list: '/Primary/?FlowAlias=bs_api_orgm_ugroup_get_user_group_list_by_company&action=api',
  get_user_group_project_list_by_page: '/Primary/?FlowAlias=bs_api_orgm_get_user_group_project_list_by_page&action=api',
  get_user_group_list_by_account: '/Primary/?FlowAlias=bs_api_ugroup_get_ugroup_list_by_account_id&action=api',
  set_hidden_user_group: '/Primary/?FlowAlias=bs_api_ugroup_set_active_user_group_by_group_code&action=api',

  assessment: {
    list: '/Primary/?FlowAlias=api_bs_tmc_get_assessment_list_by_page_and_company_code&action=api',
    entity: '/Primary/?FlowAlias=api_bs_tmc_assessment_entity_list_by_company_code_and_dimensions_id&action=api',
    get_dimensions: '/Primary/?FlowAlias=api_bs_tmc_get_global_dimensions_list_by_company_code&action=api',
    import_risks: '/Primary/?FlowAlias=api_teammate_import_risk&action=api',
    sync_data: '/Primary/?FlowAlias=api_bs_tmc_sync_assessment_by_company_code&action=api',
    get_operational_risks: '/Primary/?FlowAlias=api_get_operational_risk_by_assessment&action=api',
    create_rule_setting: '/Primary/?FlowAlias=api_create_rule_setting_for_assessment&action=api',
    update_rule_setting: '/Primary/?FlowAlias=api_create_non_performing_loan_rate&action=api',
    get_rule_setting: '/Primary/?FlowAlias=api_get_rule_setting_by_assessment_id_and_operational_risk_id&action=api',
    get_risk_scoring: '/Primary/?FlowAlias=bs_api_get_evaluations&action=api',
    calculate_score: '/Primary/?FlowAlias=api_teammate_calculate_risk_score_rule_setting&action=api',
    get_strategic_risks:
      '/Primary/?FlowAlias=api_bs_tmc_strategic_risk_list_by_parent_id_and_assessment_id_and_company_code&action=api',
    get_all_risks:
      '/Primary/?FlowAlias=api_bs_tmc_strategic_risk_list_and_risk_list_by_parent_id_and_project_id_and_company_code&action=api',
    get_risk_detail: '/Primary/?FlowAlias=api_bs_tmc_get_strategic_risk_or_risk_by_id&action=api',
    create_risk: '/Primary/?FlowAlias=api_bs_tmc_create_risk&action=api',
    update_risk: '/Primary/?FlowAlias=api_bs_tmc_update_risk&action=api',
    sync_risk: '/Primary/?FlowAlias=api_bs_tmc_sync_strategic_risk_by_company_code&action=api',
    get_hr: '/Primary/?FlowAlias=api_bs_tmc_get_hr&action=api',
    get_kri: '/Primary/?FlowAlias=api_bs_tmc_setup_category_get_category_list&action=api',
  },
  risk: {
    list: '/Primary/?FlowAlias=api_teammate_risk_get_all_risks&action=api',
    by_assessment: '/Primary/?FlowAlias=api_teammate_risk_get_risks_by_dimension&action=api',
  },
  project: {
    list: '/Primary/?FlowAlias=api_bs_tmc_project_list_by_page&action=api',
    by_assessment: '/Primary/?FlowAlias=api_bs_tmc_project_list_by_assessment_id&action=api',
    get_audits: '/Primary/?FlowAlias=api_teammate_audit_plan_get_audit_plan_by_project&action=api',
    get_members: '/Primary/?FlowAlias=api_teammate_user_get_user&action=api',
    get_issues: '/Primary/?FlowAlias=api_teammate_issue_get_all_issue_released&action=api',
    upload_data: '/Primary/?FlowAlias=api_teammate_procedure_import_procedure_by_file_upload&action=api',
    get_strategic_risks:
      '/Primary/?FlowAlias=api_teammate_strategic_risk_get_strategic_risk_and_children_by_assessmentid_and_projectid&action=api',
    get_procedure: '/Primary/?FlowAlias=api_teammate_procedure_get_procedure_by_parent_id&action=api',
  },
  issue: {
    get_recommendation: '/Primary/?FlowAlias=api_teammate_recommendation_get_recommendation_by_issueid&action=api',
  },
  issueReleased: {
    list: '/Primary/?FlowAlias=api_teammate_issue_get_all_issue_released&action=api',
  },
  issueUnReleased: {
    list: '/Primary/?FlowAlias=api_teammate_issue_get_all_issue_unreleased&action=api',
  },
  get_assistant_list: '/Primary/?FlowAlias=bs_api_chatgpt_get_assistant_list&action=api',
};
