import config from '../../../config/config.js';
import { apiEndpoints } from '../../../constants';
import axiosServices from '../../axios';

const AppService = {
  getlist: async () => {
    const data = await axiosServices.post(apiEndpoints.get_app_setting_list, {});
    return data.data.list;
  },
  detail: async (id, setView) => {
    const response = await axiosServices.post(apiEndpoints.get_app_detail, { id: id });
    setView({ ...response.data.view, action: 'detail' });
    return response.data.data;
  },
  assign: async (email_address_list, app_code) => {
    const response = await axiosServices.post(apiEndpoints.assign_account_to_app, { email_address_list, app_code });

    return response.data;
  },
  remove: async (email_address, app_code) => {
    const response = await axiosServices.post(apiEndpoints.remove_account_to_app, { email_address, app_code });

    return response.data;
  },
  getPlatforms: async () => {
    const response = await axiosServices.post(apiEndpoints.get_platform_list, {
      company_code: config.company,
      outputtype: 'RawJson',
      guest: true,
    });
    return response.data.list;
  },
  getRoleByPlatform: async (platform_id) => {
    const response = await axiosServices.post(apiEndpoints.get_role_list_by_platform, {
      platform_id,
    });
    return response.data.list;
  },
};
export default AppService;
