import React from 'react';
import { DatePicker as Picker } from '@mui/x-date-pickers/DatePicker';

const DatePicker = ({ date, onChange, disabled = false }) => {
  return (
    <Picker
      fullWidth
      format="dd/MM/yyyy"
      value={date || new Date()}
      onChange={onChange}
      disabled={disabled}
      style={{ cursor: 'pointer' }}
      slotProps={{
        textField: {
          size: 'small',
        },
      }}
    />
  );
};

export default DatePicker;
